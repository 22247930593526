import { CiLogin } from "react-icons/ci";
import aboutImage from "../../../Assets/img/image (20) (1) (1).png";
import virtualLobby from "../../../Assets/img/Virtual Lobby2.jpg";

const HamburgerNavMenu = () => {
  return (
    <div className="w-full overflow-hidden grid grid-cols-3">
      <a
        className="w-full h-full max-h-[500px] relative bg-cover bg-no-repeat flex flex-col items-center group"
        // style={{ backgroundImage: `url('${aboutImage})` }}
        href="/lobby"
      >
        <img
          src={virtualLobby}
          alt=""
          width="100%"
          height="100%"
          className="object-cover h-full"
        />
        <h3 className="text-lg font-bold text-white absolute block mx-auto bottom-4 group-hover:text-[#84CC16] transition-all duration-300 ease-in-out">
          Virtual Lobby
        </h3>
      </a>
      <a
        className="w-full h-full max-h-[500px] relative bg-cover bg-no-repeat flex flex-col items-center group"
        // style={{ backgroundImage: `url('${virtualLobby})` }}
        href="/about"
      >
        <img
          src={aboutImage}
          alt=""
          width="100%"
          height="100%"
          className="object-cover h-full"
        />
        <h3 className="text-lg font-bold text-white absolute block mx-auto bottom-4 group-hover:text-[#84CC16] transition-all duration-300 ease-in-out">
          About Us
        </h3>
      </a>
      <div className="py-8 px-8 flex flex-col">
        <a
          href="https://salesboxmarket.myshopify.com"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out flex items-center gap-4 group mb-4"
        >
          <span>Login</span>
          <CiLogin
            className={`group-hover:text-lime-500  transition-all duration-100 ease-in`}
          />
        </a>
        <hr />
        <a
          href="/about"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out mt-4 mb-8"
        >
          About Us
        </a>
        <a
          href="/team"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out mb-8"
        >
          Join The Team
        </a>
        <a
          href="/invest"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out mb-8"
        >
          Investor Relations
        </a>
        <a
          href="https://calendly.com/salesbox-meet/partners?back=1&month=2023-10"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out mb-4"
        >
          Partner Program
        </a>
        <hr />
        <a
          href="/lobby"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out mt-4 flex items-center gap-4 group"
        >
          <span>Virtual Lobby</span>
          <svg
            width="18"
            height="15"
            viewBox="0 0 889 553"
            className=" fill-black group-hover:fill-lime-500 transition-all duration-200  ease-in"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.199951 275.8C0.199951 217.4 -0.100049 159 0.399951 100.6C0.499951 88.6004 2.39995 76.4004 4.99995 64.6004C12.3 31.2004 33.7 11.2004 66.8 4.60041C80.6 1.80041 94.8 0.300415 108.9 0.300415C190.2 0.00041455 271.4 0.300415 352.7 0.400415C388.1 0.300415 423.6 0.000414543 459 0.000414543C491.8 0.000414543 524.6 -0.299585 557.4 0.500415C570.1 0.800415 583 2.60041 595.4 5.40041C629.2 13.0004 648.9 34.9004 655.4 68.5004C657.7 80.5004 659.2 92.9004 659.2 105.1C659.5 209.3 659.6 313.5 659.4 417.6C659.4 437.8 659 458.3 656.3 478.3C650.6 519.4 626.4 543 585.3 549.3C573 551.2 560.5 552.3 548 552.3C479.2 552.5 410.5 552.3 341.7 552.2C294.4 552.1 247.2 552.1 199.9 552.1C167.9 552.1 136 552.6 104 551.9C90.5 551.6 76.7 549.7 63.5 546.7C30.0999 539.1 10.6 517.3 4.19995 484.1C1.69995 471.3 0.299951 458 0.199951 445C-0.200049 388.6 -4.88274e-05 332.2 -4.88274e-05 275.8C0.0999512 275.8 0.199951 275.8 0.199951 275.8Z"
              fill=""
            />
            <path
              d="M888.7 60.5C888.7 204.4 888.7 347.6 888.7 491.4C887.1 490.7 885.8 490.3 884.6 489.6C826.6 458.3 768.7 427 710.7 395.9C706.7 393.8 705.5 391.3 705.5 387C705.6 313.3 705.6 239.6 705.5 165.9C705.5 162.6 706.2 160.6 709.4 158.8C768.2 126.6 826.9 94.2 885.6 61.9C886.3 61.6 887.1 61.3 888.7 60.5Z"
              fill=""
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default HamburgerNavMenu;
