import { FC, useEffect, useState } from "react";

const searchItems = [
  "Sales & Strategy",
  "Sales Team OnDemand",
  "Strategy & Consulting",
  "Sales Support",
  "Business Dev",
  "Campaigns OnDemand",
  "Lead Generation",
  "Email Campaign",
  "LinkedIn Campaign",
  "Sales Calls",
  "Text Campaigns",
  "Brand & Creative",
  "Creative OnDemand",
  "Brand Strategy",
  "Logo Design",
  "Stationery Design",
  "Presentation Templates",
  "Full Brand Creation",
  "Digital Marketing",
  "Marketing OnDemand",
  "SEO Analysis",
  "SEO Optimization",
  "Social Media Management",
  "Paid Ads",
  "Copy & Content",
  "Copy OnDemand",
  "Content OnDemand",
  "Website Content",
  "Content Strategy",
  "Content Management",
  "Content Creation",
  "Articles & Blogs",
  "Video Production",
  "Video OnDemand",
  "Video Marketing",
  "VSF",
  "Packages",
  "Types",
  "Explainer",
  "Live Action",
  "Web & Tech",
  "Dev OnDemand",
  "Web",
  "Funnels",
  "Mobile",
  "Digital Products",
  "Build My Team",
  "Team OnDemand",
  "Resource",
  "Fractional Roles",
  "Recruiting",
  "Coaching"
];


interface SearchNavMenuProps {
  searchTerm?: string;
  showSearch?: boolean;
}

const SearchNavMenu: FC<SearchNavMenuProps> = ({
  searchTerm,
  showSearch = false,
}) => {
  const [filteredSearch, setFilteredSearch] = useState<string[]>([]);
  const [searchVal, setSearchVal] = useState<string>("");

  const handleSearch = (searchT?: string): string[] => {
    if (searchT) {
      let sT = searchT.toLowerCase();
      return searchItems.filter((term) =>
        term.toLowerCase().includes(sT ?? "")
      );
    }
    return [];
  };

  useEffect(() => {
    setFilteredSearch(handleSearch(showSearch ? searchVal : searchTerm));
  }, [searchTerm, searchVal]);

  return (
    <div className="w-full max-w-[1500px] mx-auto py-10 px-4 ">
      {showSearch && (
        <div className="relative w-full border border-black rounded-full overflow-hidden mb-10">
          <input
            onChange={(e) => setSearchVal(e.target.value)}
            type="text"
            placeholder="Find your perfect solution"
            className="w-full relative z-0 px-4 py-1.5 bg-transparent outline-none text-black placeholder:text-black text-sm placeholder:text-sm"
          />
          <svg
            width="15"
            className="absolute right-4 top-[calc(50%-8px)] z-[1]"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_24265_11046)">
              <path
                d="M11.009 10.1977C11.9167 8.95899 12.3233 7.42318 12.1474 5.89754C11.9714 4.37191 11.2259 2.96897 10.06 1.9694C8.89408 0.96983 7.39373 0.447345 5.85913 0.506476C4.32452 0.565607 2.86883 1.20199 1.78328 2.28832C0.697734 3.37464 0.0623891 4.83079 0.00435612 6.36544C-0.0536768 7.90008 0.469882 9.40005 1.47029 10.5653C2.47069 11.7305 3.87416 12.475 5.39992 12.6498C6.92568 12.8247 8.4612 12.417 9.69928 11.5083H9.69834C9.72647 11.5458 9.75647 11.5815 9.79022 11.6162L13.3996 15.2255C13.5754 15.4014 13.8139 15.5003 14.0625 15.5004C14.3112 15.5005 14.5498 15.4018 14.7257 15.226C14.9016 15.0502 15.0005 14.8117 15.0006 14.563C15.0007 14.3144 14.9019 14.0758 14.7262 13.8999L11.1168 10.2905C11.0833 10.2566 11.0472 10.2262 11.009 10.1977ZM11.2508 6.59397C11.2508 7.27109 11.1175 7.94159 10.8583 8.56718C10.5992 9.19276 10.2194 9.76118 9.74061 10.24C9.26181 10.7188 8.69339 11.0986 8.0678 11.3577C7.44222 11.6168 6.77172 11.7502 6.09459 11.7502C5.41746 11.7502 4.74697 11.6168 4.12138 11.3577C3.4958 11.0986 2.92737 10.7188 2.44857 10.24C1.96977 9.76118 1.58996 9.19276 1.33084 8.56718C1.07171 7.94159 0.938342 7.27109 0.938342 6.59397C0.938342 5.22644 1.48159 3.91493 2.44857 2.94795C3.41556 1.98096 4.72707 1.43772 6.09459 1.43772C7.46211 1.43772 8.77363 1.98096 9.74061 2.94795C10.7076 3.91493 11.2508 5.22644 11.2508 6.59397Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_24265_11046">
                <rect
                  width="15"
                  height="15"
                  fill="black"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      <div className="w-[15rem] space-y-6">
        {searchTerm || searchVal ? (
          filteredSearch.length > 0 ? (
            filteredSearch.map((item, index) => (
              <a
                key={index}
                href="https://salesboxmarket.myshopify.com"
                className="flex text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                {item}
              </a>
            ))
          ) : (
            <h5 className="text-xl text-black ">No results</h5>
          )
        ) : (
          <>
            <a
              href="https://salesboxmarket.myshopify.com"
              className="flex items-center justify-between gap-4 text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
            >
              <span>All Solutions</span>
              <svg
                width="8.25"
                height="15"
                viewBox="0 0 33 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://salesboxmarket.myshopify.com"
              className="flex items-center justify-between gap-4 text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
            >
              <span>
                onDemand<span>&#8482;</span>
              </span>
              <svg
                width="8.25"
                height="15"
                viewBox="0 0 33 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://salesboxmarket.myshopify.com"
              className="flex items-center justify-between gap-4 text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
            >
              <span>Bundles</span>
              <svg
                width="8.25"
                height="15"
                viewBox="0 0 33 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://salesboxmarket.myshopify.com"
              className="flex items-center justify-between gap-4 text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
            >
              <span>Add Ons</span>
              <svg
                width="8.25"
                height="15"
                viewBox="0 0 33 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://salesboxmarket.myshopify.com"
              className="flex items-center justify-between gap-4 text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
            >
              <span>Customize</span>
              <svg
                width="8.25"
                height="15"
                viewBox="0 0 33 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <a
              href="https://salesboxmarket.myshopify.com"
              className="flex items-center justify-between gap-4 text-xl text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
            >
              <span>Explore</span>
              <svg
                width="8.25"
                height="15"
                viewBox="0 0 33 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </>
        )}
      </div>
      <div className="flex-1"></div>
    </div>
  );
};

export default SearchNavMenu;
