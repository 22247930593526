import member01 from "../../../Assets/homev2/Navbar/members-1.png";
import advantage from "../../../Assets/homev2/Navbar/Advantage.svg";

const MembersNavMenu = () => {
  return (
    <div className="w-full max-w-[1500px] mx-auto flex justify-between py-8 px-4">
      <div className="flex-1 grid grid-cols-2 gap-10 pr-10">
        <div className="w-3/4 flex items-center justify-center">
          <img src={member01} alt="" width="100%" height="100%" />
        </div>
        <div className="flex flex-col justify-center">
          <h4 className="text-5xl font-medium mb-2">Salesmrkt</h4>
          <div className="w-96 mb-2">
            <img src={advantage} alt="" width="100%" height="100%" />
          </div>
          <h4 className="text-2xl mb-4">
            <span>Exclusive</span> | <span>Empower</span> |{" "}
            <span>Accelerate</span>
          </h4>
          <a href="https://salesboxmarket.myshopify.com/" className="text-lg font-medium py-2.5 px-8 rounded-full border border-[#84CC16] text-white bg-[#84CC16] w-max">
            Subscribe Now
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-8 min-w-[12rem]">
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Membership Advantage
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          #SalesExperience
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Tutorial Videos
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Insights
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Tools & Tricks
        </a>
      </div>
    </div>
  );
};

export default MembersNavMenu;
