import { FC, useState } from "react";
import downArrow from "../../Assets/arrow-down.png";
import merch from "../../Assets/homev2/footer-merch.png";
import payments from "../../Assets/homev2/footer-payments.png";
import trusted from "../../Assets/homev2/footer-trusted-partner.png";
import logo from "../../Assets/homev2/logo.png";
import EmailInputV2 from "../Communication/EmailInputV2";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  YoutubeIcon,
} from "../Icons";

const footerInfo = [
  {
    title: "Solutions",
    links: (
      <>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Sales & Strategy
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Business Development
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Brand & Creative
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Digital Marketing
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Copy & Content
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Video Production
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Web & Tech
        </a>
        <a
          href="/talent-on-demand"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Build My Team
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Customize My Solution
        </a>
      </>
    ),

    id: 0,
  },
  {
    title: "Membership",
    links: (
      <>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Enterprise
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Growth
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Launch
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Experience
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          #SalesExperience
        </a>
      </>
    ),

    id: 1,
  },
  {
    title: "Resources",
    links: (
      <>
        <a
          href="/lobby"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Enter Virtual Lobby
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Value Content
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          Tools
        </a>
        <a
          href="/about"
          className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
        >
          About Us
        </a>
        {/* <a
                href="https://salesboxmarket.myshopify.com/"
                className="text-white min-w-max hover:text-[#84CC16] transition-all ease-in-out duration-300"
              >
                Find us on
              </a> */}
      </>
    ),
    id: 2,
  },
];

const FooterV2: FC = () => {
  const [promosCliked, setpromosCliked] = useState(false);

  const handelPromos = () => {
    setpromosCliked(true);
    setTimeout(() => {
      setpromosCliked(false);
    }, 1500);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const isActive = (index: number) => {
    return activeIndex === index;
  };

  return (
    <footer className="bg-[#222222] w-full">
      <div className="w-full max-w-[1500px] mx-auto px-4 py-4 lg:py-10">
        <div className="pt-4 lg:pt-10 pb-6  md:pb-10 flex flex-col md:flex-row md:flex-wrap md:gap-10 justify-between">
          {footerInfo.map((info) => {
            return (
              <div className="space-y-2 md:space-y-8" key={info.id}>
                <h3 className="text-xl hidden md:block text-white font-bold">
                  {info.title}
                </h3>

                <div className="flex items-center  justify-between border-b md:border-none md:hidden border-[#707070] py-3">
                  <h3 className="text-xl text-white font-bold">{info.title}</h3>
                  <img
                    src={downArrow}
                    alt="downArrow"
                    onClick={() =>
                      setActiveIndex(isActive(info.id) ? null : info.id)
                    }
                    className={`transition-all duration-500 ease-in-out ${
                      isActive(info.id) && "transform rotate-180"
                    } md:hidden w-3 h-2 cursor-pointer `}
                  />
                </div>
                <div
                  className={`flex md:max-h-96 md:opacity-100 overflow-hidden flex-col gap-4  md:transition-none transition-all duration-500 ease-in-out transform ${
                    isActive(info.id)
                      ? "max-h-96 opacity-100 translate-y-0"
                      : "max-h-0 opacity-0 -translate-y-4"
                  } `}
                >
                  {info.links}
                </div>
              </div>
            );
          })}

          <div className="space-y-8 hidden md:block mx-auto lg:mx-0">
            <h3 className="text-xl text-white font-bold">Got Merch?</h3>
            <img
              src={merch}
              alt="Merchandize"
              width="100%"
              height="100%"
              loading="lazy"
            />
          </div>
          <div className="my-10 md:space-y-8 md:mx-auto 2xl:mx-0 md:my-0 text-center md:text-start   ">
            <h3 className="text-xl mb-4 md:mb-0 text-white font-bold">
              Receive News & Updates
            </h3>
            <EmailInputV2 isCliked={promosCliked} />
            <div className="w-max hidden md:block md:ml-auto">
              <img src={trusted} alt="Trusted Brands" />
            </div>
          </div>
        </div>
        <div className="space-y-6 text-center md:hidden">
          <h3 className="text-xl text-white font-bold">Got Merch?</h3>
          <img
            src={merch}
            alt="Merchandize"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </div>
        <div className=" flex md:hidden justify-center my-10 items-center gap-6 text-white">
          <a
            href="https://www.linkedin.com/company/sales-mrkt/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://www.youtube.com/@salesmrkt"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
          <a
            href="https://www.instagram.com/salesmrkt_/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.facebook.com/Salesmrkt.B2B.Marketplace"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
        </div>

        <div className="w-max  mx-auto md:hidden md:ml-auto">
          <img src={trusted} alt="Trusted  Partener" />
        </div>
        <hr className="w-full  border-b border-[#707070] my-7" />
        <div className="flex items-center  justify-center md:justify-between flex-wrap gap-10 py-10">
          <div className="flex items-center md:items-end text-white text-xs gap-4">
            <a href="/">
              <img
                src={logo}
                alt="Salesmrkt Logo "
                width="100%"
                height="100%"
                loading="lazy"
              />
            </a>
            <h4>
              © {new Date().getFullYear()}, Salesmrkt. All Rights Reserved.
            </h4>
          </div>
          <div className=" hidden md:flex items-center gap-6 text-white">
            <a
              href="https://www.linkedin.com/company/sales-mrkt/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://www.youtube.com/@salesmrkt"
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeIcon />
            </a>
            <a
              href="https://www.instagram.com/salesmrkt_/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/Salesmrkt.B2B.Marketplace"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
          </div>
          <div>
            <img src={payments} alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterV2;
