import React, { useState, useRef, useEffect, SyntheticEvent } from "react";
import { AiOutlineSend } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import { BarLoader } from "react-spinners";
interface EmailInpit {
  isCliked: boolean;
}
const EmailInputV2: React.FC<EmailInpit> = ({ isCliked }) => {
  const [isOpen, setOpen] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailure, setFailure] = useState(false);
  const [messageCount, setMessageCount] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [InvalidEmail, setInvalidEmail] = useState(false);
  const [sentMessageprompt, setSentMessagePrompt] = useState(false);
  const [emailValue, setEmailValue] = useState(""); // State to hold email value
  const form = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const emailPattern =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  const sendEmail = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (messageCount >= 2 || isSending) {
      // If the user has already sent 2 messages or is currently sending, return without sending a new message.
      return;
    }
    if (!emailPattern.test(emailValue)) {
      setInvalidEmail(true);
      setTimeout(() => {
        setInvalidEmail(false);
      }, 3000);
      return;
    }
    setIsSending(true);
    if (form.current) {
      // Use the emailValue state instead of directly getting it from the form data
      const emailData = {
        form_email: emailValue,
      };

      // Try the first service
      emailjs
        .send(
          "service_9sbma8h",
          "template_voh5juo",
          emailData,
          "YX6Ix4FNwXw3hRdaD"
        )
        .then(
          (result) => {
            setSuccess(true);
            setMessageCount((prevCount) => prevCount + 1);
            setTimeout(() => {
              setSuccess(false);
              setIsSending(false);
              setTimeout(() => {
                setSentMessagePrompt(true);
                setEmailValue("");
              }, 3000);
              setTimeout(() => {
                setSentMessagePrompt(false);
              }, 1500);
            }, 3000);
          },
          (error) => {
            // If the first service fails, try the second one
            emailjs
              .send(
                "service_eqeh5lk",
                "template_voh5juo",
                emailData,
                "YX6Ix4FNwXw3hRdaD"
              )
              .then(
                (result) => {
                  setSuccess(true);

                  setMessageCount((prevCount) => prevCount + 1);
                  setTimeout(() => {
                    setSuccess(false);
                    setIsSending(false);
                    setTimeout(() => {
                      setSentMessagePrompt(true);
                      setEmailValue("");
                    }, 3000);
                    setTimeout(() => {
                      setSentMessagePrompt(false);
                    }, 1500);
                  }, 3000);
                },
                (error) => {
                  setFailure(true);
                  setIsSending(false);
                  setSentMessagePrompt(false);
                  setTimeout(() => {
                    setFailure(false);
                  }, 1500);
                }
              );
          }
        );
    }
  };

  // Add an onChange handler to capture the email value
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  useEffect(() => {
    if (messageCount >= 2) {
      setIsWaiting(true);
      setIsSending(false);
      const timer = setTimeout(() => {
        setIsWaiting(false);
        setMessageCount(0);
      }, 9000);
      return () => clearTimeout(timer);
    }
  }, [messageCount]);
  useEffect(() => {
    if (isCliked && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCliked]);

  return (
    <div>
      <form
        className="rounded-full overflow-hidden relative md:min-w-[320px]"
        onSubmit={sendEmail}
        ref={form}
      >
        <input
          ref={inputRef}
          type="email"
          placeholder="Enter your Email"
          className="placeholder:text-[#222222] text-[#222222] bg-white w-full py-2 px-4 md:pl-6 md:pr-16 outline-none"
          value={emailValue} // Set the email value in the input
          onChange={handleEmailChange} // Add onChange handler
          required
        />
        {/* <svg
        width="21"
        height="15"
        className="absolute right-4 top-[calc(50%-7.5px)] z-[1]"
        viewBox="0 0 21 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.57986 7.50001L1.93686 11.9118L0.536848 13.2577C0.186844 12.7278 0 12.1324 0 11.4706V3.52943C0 2.86766 0.186851 2.27224 0.513164 1.74231L1.91318 3.08825L6.57986 7.50001ZM17.757 13.1689L13.0903 8.75718L11.1535 10.6097C10.9666 10.7864 10.7333 10.8743 10.4999 10.8743C10.2666 10.8743 10.0096 10.7864 9.84643 10.6097L7.90956 8.75718L3.24284 13.1689L1.84283 14.5149C2.37968 14.8233 3.03321 15 3.73322 15H17.2667C17.9667 15 18.6202 14.8234 19.1799 14.5149L17.757 13.1689ZM20.4869 1.7423L19.0869 3.08823L14.4201 7.49999L19.0631 11.9118L20.4632 13.2577C20.8132 12.7278 21 12.1323 21 11.4706V3.52941C21 2.86764 20.8131 2.27222 20.4868 1.7423H20.4869ZM17.2666 0H3.73316C3.03315 0 2.37964 0.176643 1.81998 0.485128L3.22 1.80866L8.54025 6.85975L10.4771 8.71233L12.414 6.85975L17.7342 1.80866L19.1342 0.485128C18.6202 0.176654 17.9667 0 17.2666 0Z"
          fill="#212121"
        />
      </svg> */}
        <div
          className="flex absolute right-0 top-[50%] items-center justify-center"
          rel="noopener noreferrer"
        >
          <button
            type="submit"
            className="text-black absolute p-1 right-0 mr-2 rounded-full"
          >
            <AiOutlineSend
              className={`text-xl  hover:text-[#84CC16] hover:scale-110 duration-150 ease-in hover:animate-pulse ${
                isFailure
                  ? "text-xl text-red-500  duration-150 ease-in animate-ping"
                  : isSending
                  ? "text-xl text-[#84CC16]   duration-150 ease-in animate-pulse"
                  : "text-black"
              }`}
            />
          </button>
        </div>
      </form>

      {isFailure && (
        <div className=" flex flex-col items-end justify-center rounded-lg pr-2 pt-3  text-red-500 w-full">
          <p className="text-xs">Try again later</p>
        </div>
      )}
      {InvalidEmail && (
        <div className=" flex flex-col items-end justify-center rounded-lg pr-2 pt-3  text-orange-500 w-full">
          <p className="text-xs">Invalid Email</p>
        </div>
      )}
      {isSending && (
        <div className=" flex flex-col items-end justify-center rounded-lg pr-2 pt-3  text-white w-full">
          <p className="text-xs">Sending...</p>
          <BarLoader color="#99CC00" height={3} width={70} />
        </div>
      )}

      {sentMessageprompt && (
        <div className="flex flex-row gap-3 w-fit ml-auto justify-center rounded-lg pr-2 pt-3  text-lime-500">
          <p className="text-xs">Message Sent!</p>
          <div>
            <svg
              className="fill-[#84CC16]  "
              fill=""
              height="20px"
              width="20px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <g>
                <path
                  d="M474.045,173.813c-4.201,1.371-6.494,5.888-5.123,10.088c7.571,23.199,11.411,47.457,11.411,72.1
                c0,62.014-24.149,120.315-68,164.166s-102.153,68-164.167,68s-120.316-24.149-164.167-68S16,318.014,16,256
                S40.149,135.684,84,91.833s102.153-68,164.167-68c32.889,0,64.668,6.734,94.455,20.017c28.781,12.834,54.287,31.108,75.81,54.315
                c3.004,3.239,8.066,3.431,11.306,0.425c3.24-3.004,3.43-8.065,0.426-11.306c-23-24.799-50.26-44.328-81.024-58.047
                C317.287,15.035,283.316,7.833,248.167,7.833c-66.288,0-128.608,25.813-175.48,72.687C25.814,127.392,0,189.712,0,256
                c0,66.287,25.814,128.607,72.687,175.479c46.872,46.873,109.192,72.687,175.48,72.687s128.608-25.813,175.48-72.687
                c46.873-46.872,72.687-109.192,72.687-175.479c0-26.332-4.105-52.26-12.201-77.064
                C482.762,174.736,478.245,172.445,474.045,173.813z"
                />
                <path
                  d="M504.969,83.262c-4.532-4.538-10.563-7.037-16.98-7.037s-12.448,2.499-16.978,7.034l-7.161,7.161
                c-3.124,3.124-3.124,8.189,0,11.313c3.124,3.123,8.19,3.124,11.314-0.001l7.164-7.164c1.51-1.512,3.52-2.344,5.66-2.344
                s4.15,0.832,5.664,2.348c1.514,1.514,2.348,3.524,2.348,5.663s-0.834,4.149-2.348,5.663L217.802,381.75
                c-1.51,1.512-3.52,2.344-5.66,2.344s-4.15-0.832-5.664-2.348L98.747,274.015c-1.514-1.514-2.348-3.524-2.348-5.663
                c0-2.138,0.834-4.149,2.351-5.667c1.51-1.512,3.52-2.344,5.66-2.344s4.15,0.832,5.664,2.348l96.411,96.411
                c1.5,1.5,3.535,2.343,5.657,2.343s4.157-0.843,5.657-2.343l234.849-234.849c3.125-3.125,3.125-8.189,0-11.314
                c-3.124-3.123-8.189-3.123-11.313,0L212.142,342.129l-90.75-90.751c-4.533-4.538-10.563-7.037-16.98-7.037
                s-12.448,2.499-16.978,7.034c-4.536,4.536-7.034,10.565-7.034,16.977c0,6.412,2.498,12.441,7.034,16.978l107.728,107.728
                c4.532,4.538,10.563,7.037,16.98,7.037c6.417,0,12.448-2.499,16.977-7.033l275.847-275.848c4.536-4.536,7.034-10.565,7.034-16.978
                S509.502,87.794,504.969,83.262z"
                />
              </g>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailInputV2;
