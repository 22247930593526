import { FC, useState } from "react";
import SearchNavMenu from "./SearchNavMenu";
import { CiLogin } from "react-icons/ci";
import { shopData } from "./ShopNavMenu";

interface SidebarV2Props {
  showSearch: boolean;
}

const SidebarV2: FC<SidebarV2Props> = ({ showSearch }) => {
  const [shopMenu, setShopMenu] = useState<number | null>(null);

  return (
    <div>
      {showSearch ? (
        <SearchNavMenu showSearch={showSearch} />
      ) : (
        <div className="py-8 px-4 space-y-4">
          <div className=" flex flex-col gap-4">
            <a
              href="https://salesboxmarket.myshopify.com"
              className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out flex items-center gap-4 group "
            >
              <span>Login</span>
              <CiLogin
                className={`group-hover:text-lime-500  transition-all duration-100 ease-in`}
              />
            </a>
            <a
              href="/about"
              className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out "
            >
              About Us
            </a>
            <a
              href="/team"
              className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out "
            >
              Join The Team
            </a>
            <a
              href="/invest"
              className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out "
            >
              Investor Relations
            </a>
            <a
              href="https://calendly.com/salesbox-meet/partners?back=1&month=2023-10"
              className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out "
            >
              Partner Program
            </a>
            <a
              href="/lobby"
              className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out flex items-center gap-4 group"
            >
              <span>Virtual Lobby</span>
              <svg
                width="18"
                height="15"
                viewBox="0 0 889 553"
                className=" fill-black group-hover:fill-lime-500 transition-all duration-200  ease-in"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.199951 275.8C0.199951 217.4 -0.100049 159 0.399951 100.6C0.499951 88.6004 2.39995 76.4004 4.99995 64.6004C12.3 31.2004 33.7 11.2004 66.8 4.60041C80.6 1.80041 94.8 0.300415 108.9 0.300415C190.2 0.00041455 271.4 0.300415 352.7 0.400415C388.1 0.300415 423.6 0.000414543 459 0.000414543C491.8 0.000414543 524.6 -0.299585 557.4 0.500415C570.1 0.800415 583 2.60041 595.4 5.40041C629.2 13.0004 648.9 34.9004 655.4 68.5004C657.7 80.5004 659.2 92.9004 659.2 105.1C659.5 209.3 659.6 313.5 659.4 417.6C659.4 437.8 659 458.3 656.3 478.3C650.6 519.4 626.4 543 585.3 549.3C573 551.2 560.5 552.3 548 552.3C479.2 552.5 410.5 552.3 341.7 552.2C294.4 552.1 247.2 552.1 199.9 552.1C167.9 552.1 136 552.6 104 551.9C90.5 551.6 76.7 549.7 63.5 546.7C30.0999 539.1 10.6 517.3 4.19995 484.1C1.69995 471.3 0.299951 458 0.199951 445C-0.200049 388.6 -4.88274e-05 332.2 -4.88274e-05 275.8C0.0999512 275.8 0.199951 275.8 0.199951 275.8Z"
                  fill=""
                />
                <path
                  d="M888.7 60.5C888.7 204.4 888.7 347.6 888.7 491.4C887.1 490.7 885.8 490.3 884.6 489.6C826.6 458.3 768.7 427 710.7 395.9C706.7 393.8 705.5 391.3 705.5 387C705.6 313.3 705.6 239.6 705.5 165.9C705.5 162.6 706.2 160.6 709.4 158.8C768.2 126.6 826.9 94.2 885.6 61.9C886.3 61.6 887.1 61.3 888.7 60.5Z"
                  fill=""
                />
              </svg>
            </a>
          </div>
          <hr />
          <div className="space-y-5">
            <h3 className="text-black text-xl font-bold text-center">
              Members
            </h3>
            <div className="flex flex-col gap-4">
              <a
                href="/"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Membership Advantage
              </a>
              <a
                href="/"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                #SalesExperience
              </a>
              <a
                href="/"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Tutorial Videos
              </a>
              <a
                href="/"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Insights
              </a>
              <a
                href="/"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Tools & Tricks
              </a>
            </div>
          </div>
          <hr />
          <div className="space-y-5">
            <h3 className="text-black text-xl font-bold text-center">
              Let's Talk
            </h3>
            <div className="flex flex-col gap-4">
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Live Meeting
              </a>
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Schedule Meeting
              </a>
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                Send More Info
              </a>
            </div>
          </div>
          <hr />
          <div className="space-y-5">
            <h3 className="text-black text-xl font-bold text-center">Shop</h3>
            <div className="flex flex-col gap-4">
              {shopData.map((data, index) => {
                return (
                  <div key={index} className="space-y-4">
                    <div
                      className="flex items-center gap-4 text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out cursor-pointer"
                      onClick={() =>
                        setShopMenu((prevState) =>
                          prevState === index ? null : index
                        )
                      }
                    >
                      <a href={data.link}>{data.title}</a>
                      <svg
                        width="8.25"
                        height="15"
                        viewBox="0 0 33 60"
                        fill="none"
                        className={`${shopMenu === index && "rotate-90"}`}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div
                      className={`flex flex-col gap-2 overflow-hidden transition-all ${shopMenu === index ? "py-2 h-auto" : "py-0 h-0"
                        }`}
                    >
                      {data.items.map((item, i) => (
                        <a
                          href={item.link}
                          key={i}
                          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
                        >
                          {item.title}
                          {item.withTradeMark && <span>&#8482;</span>}
                        </a>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarV2;
