import { ButtonProps, ButtonSize, ButtonTheme } from "./index";
import { FC } from "react";

const OutlineButton: FC<ButtonProps> = ({
  children,
  theme = "white",
  size = "md",
  ...props
}) => {
  const buttonSizeClass = getSize(size);
  const buttonThemeClass = getTheme(theme);

  return (
    <button
      {...props}
      className={`font-medium rounded-full border relative group flex items-center justify-center overflow-hidden  transition-all duration-500 ease-in-out w-full md:w-max ${buttonSizeClass} ${buttonThemeClass}`}
    >
      <div
        className={`absolute z-0 ${
          theme === "black" ? "bg-black" : "bg-white"
        } w-60 h-60 rotate-45 right-[60%] -top-[550%] group-hover:right-0 group-hover:-top-[150%] transition-all duration-500 ease-in-out hidden lg:block`}
      />
      <span className="flex items-center gap-2 relative z-[1]">{children}</span>
    </button>
  );
};

const getTheme = (theme: ButtonTheme): string => {
  if (theme === "black") {
    return "border-black text-black hover:text-white";
  }

  return "border-white text-white hover:text-black";
};
const getSize = (size: ButtonSize): string => {
  if (size === "sm") {
    return "py-2 px-6 text-base";
  }

  return "py-2 md:py-2.5 px-6 md:text-lg";
};

export default OutlineButton;
