import { FC, useEffect, useRef, useState } from "react";
import shop01 from "../../../Assets/homev2/Navbar/shop-01.png";
import shop02 from "../../../Assets/homev2/Navbar/shop-02.png";
import shop03 from "../../../Assets/homev2/Navbar/shop-03.png";
import shop1 from "../../../Assets/homev2/Navbar/shop-1.mp4";
import shop2 from "../../../Assets/homev2/Navbar/shop-2.mp4";
import shop3 from "../../../Assets/homev2/Navbar/shop-3.mp4";

interface OnDemandImageProps {
  src2: string;
  src: string;
  alt: string;
}

export const shopData = [
  {
    title: "Sales & Strategy",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Sales Team OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      {
        title: "Strategy & Consulting",
        link: "https://salesboxmarket.myshopify.com/",
      },
      { title: "Sales Support", link: "https://salesboxmarket.myshopify.com/" },
    ],
  },
  {
    title: "Business Dev",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Campaigns OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      {
        title: "Lead Generation",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Email Campaign",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "LinkedIn Campaign",
        link: "https://salesboxmarket.myshopify.com/",
      },
      { title: "Sales Calls", link: "https://salesboxmarket.myshopify.com/" },
      {
        title: "Text Campaigns",
        link: "https://salesboxmarket.myshopify.com/",
      },
    ],
  },
  {
    title: "Brand & Creative",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Creative OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      {
        title: "Brand Strategy",
        link: "https://salesboxmarket.myshopify.com/",
      },
      { title: "Logo Design", link: "https://salesboxmarket.myshopify.com/" },
      {
        title: "Stationery Design",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Presentation Templates",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Full Brand Creation",
        link: "https://salesboxmarket.myshopify.com/",
      },
    ],
  },
  {
    title: "Digital Marketing",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Marketing OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      { title: "SEO Analysis", link: "https://salesboxmarket.myshopify.com/" },
      {
        title: "SEO Optimization",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Social Media Management",
        link: "https://salesboxmarket.myshopify.com/",
      },
      { title: "Paid Ads", link: "https://salesboxmarket.myshopify.com/" },
    ],
  },
  {
    title: "Copy & Content",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Copy OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      {
        title: "Content OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      {
        title: "Website Content",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Content Strategy",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Content Management",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Content Creation",
        link: "https://salesboxmarket.myshopify.com/",
      },
      {
        title: "Articles & Blogs",
        link: "https://salesboxmarket.myshopify.com/",
      },
    ],
  },
  {
    title: "Video Production",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Video OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      {
        title: "Video Marketing",
        link: "https://salesboxmarket.myshopify.com/",
      },
      { title: "VSF", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Packages", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Types", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Explainer", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Live Action", link: "https://salesboxmarket.myshopify.com/" },
    ],
  },
  {
    title: "Web & Tech",
    link: "https://salesboxmarket.myshopify.com/",
    items: [
      {
        title: "Dev OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      { title: "Web", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Funnels", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Mobile", link: "https://salesboxmarket.myshopify.com/" },
      {
        title: "Digital Products",
        link: "https://salesboxmarket.myshopify.com/",
      },
    ],
  },
  {
    title: "Build My Team",
    link: "/talent-on-demand",
    items: [
      {
        title: "Team OnDemand",
        link: "https://salesboxmarket.myshopify.com/",
        withTradeMark: true,
      },
      { title: "Resource", link: "https://salesboxmarket.myshopify.com/" },
      {
        title: "Fractional Roles",
        link: "https://salesboxmarket.myshopify.com/",
      },
      { title: "Recruiting", link: "https://salesboxmarket.myshopify.com/" },
      { title: "Coaching", link: "https://salesboxmarket.myshopify.com/" },
    ],
  },
];

const ShopNavMenu: FC = () => {
  const [menuOpen, setMenuOpen] = useState<number | null>(null);

  // const shopMenuCloseTimeOutRef = useRef<number | NodeJS.Timeout>();

  const handleMouseOvershopMenuZone = (menuType: number): void => {
    // console.log("shop timeout", shopMenuCloseTimeOutRef.current);

    // clearTimeout(shopMenuCloseTimeOutRef.current);
    // console.log("shop timeout cleared", shopMenuCloseTimeOutRef.current);
    // shopMenuCloseTimeOutRef.current = undefined;
    setMenuOpen(menuType);
  };
  const handleMouseOverNotshopMenuZone = () => {
    if (!!menuOpen) {
      // let shopMenuCloseTimeOut;
      // if (!shopMenuCloseTimeOutRef.current) {
      //   shopMenuCloseTimeOut = setTimeout(() => setMenuOpen(null), 1500);
      // }
      setMenuOpen(null);
      // console.log(
      //   "shop timeout created",
      //   shopMenuCloseTimeOut,
      //   shopMenuCloseTimeOutRef.current
      // );
      // shopMenuCloseTimeOutRef.current = shopMenuCloseTimeOut;
    }
  };

  return (
    <div className="w-full max-w-[1500px] mx-auto flex justify-between py-8 px-4">
      <div
        className="w-[20rem] relative"
        onMouseLeave={() => handleMouseOverNotshopMenuZone()}
      >
        <div className="space-y-6 relative z-[3] bg-white pr-10">
          {shopData.map((data, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-between text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out cursor-pointer"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  handleMouseOvershopMenuZone(index);
                }}
              >
                <a href={data.link}>{data.title}</a>
                <svg
                  width="8.25"
                  height="15"
                  viewBox="0 0 33 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.53917 1.66754L32.1636 27.8206C32.4677 28.1319 32.6827 28.4692 32.8084 28.8325C32.9361 29.1957 33 29.5849 33 30C33 30.4151 32.9361 30.8043 32.8084 31.1675C32.6827 31.5308 32.4677 31.8681 32.1636 32.1794L6.53917 58.4103C5.82949 59.1368 4.9424 59.5 3.87788 59.5C2.81337 59.5 1.90092 59.1108 1.14056 58.3325C0.380188 57.5541 3.56519e-06 56.646 3.47446e-06 55.6082C3.38373e-06 54.5704 0.380187 53.6623 1.14056 52.8839L23.4954 30L1.14055 7.1161C0.430873 6.38962 0.0760336 5.49502 0.0760335 4.4323C0.0760335 3.3675 0.456218 2.44591 1.21659 1.66754C1.97695 0.889182 2.86405 0.499999 3.87788 0.499999C4.8917 0.499999 5.7788 0.889182 6.53917 1.66754Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            );
          })}
        </div>
        <div
          className={`w-[20rem] bg-white flex flex-col gap-4 absolute h-full z-[2] top-0 ${
            menuOpen === null ? "-right-0" : "-right-full"
          }`}
          onMouseEnter={(e) => {
            e.stopPropagation();
            // clearTimeout(shopMenuCloseTimeOutRef.current);
            // console.log(
            //   "shop timeout cleared act",
            //   shopMenuCloseTimeOutRef.current
            // );
            // shopMenuCloseTimeOutRef.current = undefined;
          }}
          onMouseLeave={() => handleMouseOverNotshopMenuZone()}
        >
          {menuOpen !== null ? (
            shopData[menuOpen].items.map((data, index) => (
              <a
                href={data.link}
                key={index}
                className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
              >
                {data.title}
                {data.withTradeMark && <span>&#8482;</span>}
              </a>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex-1 grid grid-cols-3 gap-10 pl-10 items-center justify-center">
        <OnDemandImage src={shop1} src2={shop01} alt="" />
        <OnDemandImage src={shop2} src2={shop02} alt="" />
        <OnDemandImage src={shop3} src2={shop03} alt="" />
      </div>
    </div>
  );
};

const OnDemandImage: FC<OnDemandImageProps> = ({
  src2,
  src,
  alt,
  ...props
}) => {
  const [img, setImg] = useState(src);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.addEventListener("mouseover", () =>
        videoRef.current?.play()
      );
      videoRef.current?.addEventListener("mouseleave", () =>
        videoRef.current?.pause()
      );

      return () => {
        videoRef.current?.removeEventListener("mouseover", () => {});
        videoRef.current?.removeEventListener("mouseleave", () => {});
      };
    }
  }, []);

  return (
    <video
      src={src}
      ref={videoRef}
      {...props}
      width="100%"
      muted
      loop
      height="100%"
      className="cursor-pointer"
    />
  );
};

export default ShopNavMenu;
