import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import PlaceholderImage from "../../Assets/TalentOnDemand/talent-on-demand-placeholder.png";
import IntroVideo from "../../Assets/Video/Salesmrkt - Welcome to the Future of Sales, Marketing & Tech (1).mp4";
import Loading from "../../Loading";
import NewContactUs from "../Communication/NewContact";
import FooterV2 from "../HomeV2/FooterV2";
import NavbarV2 from "../HomeV2/NavbarV2";
import LoginFullPagePaul from "../LoginMenu/LoginFullPagePaul";
import VideoEmbed from "../YoutubeEmbed/VideoEmbed";

const TalentOnDemandPage: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [LoginMenuOpener, setLoginMenuOpener] = useState(false);
  const [LetsTalkOpener, setLetsTalkOpener] = useState(false);
  const [navFour, setNavFour] = useState(true);
  const [FooterSelected, setFooterSelected] = useState(18);
  const [bundles, setBundles] = useState(false);
  const [navTwo, setNavTwo] = useState(false);
  const [navThree, setNavThree] = useState(false);
  const [navOne, setNavOne] = useState(false);
  const [exploreButton, setExploreButton] = useState(false);
  const [resources, setresources] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Simulate loading for 1 second
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1900);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const parseIdFromUrl = (url: string): number | null => {
      const match = url.match(/#SolutionsID=(\d+)/);
      if (match) {
        return parseInt(match[1], 10);
      }
      return null;
    };

    const idFromUrl = parseIdFromUrl(location.hash);

    // If a valid ID is found in the URL, update the state
    if (idFromUrl !== null) {
      setFooterSelected(29);
      const targetElement = document.getElementById("Solutions");
      if (targetElement) {
        window.scrollTo({
          behavior: "smooth",
          top: targetElement.offsetTop,
        });
      }
    }
  }, [location]);

  return (
    <div className="h-full w-full overflow-hidden differentCursor">
      <Helmet>
        <title>Talent On Demand | Salesmrkt</title>
      </Helmet>
      <Loading isLoading={isLoading} />

      <LoginFullPagePaul
        LoginMenuOpener={LoginMenuOpener}
        setLoginMenuOpener={setLoginMenuOpener}
      />
      <NavbarV2 />
      <NewContactUs open={isContactOpen} setOpen={setIsContactOpen} />
      <nav className=" mt-[80px] z-10 bg-white hidden lg:flex items-center justify-center gap-5 xl:gap-10 w-full px-4 py-4 text-sm xl:text-base">
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Sales & Strategy
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Business Development
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Brand & Creative
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Digital Marketing
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Copy & Content
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Video
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Tech & AI
        </a>
        <a
          href="/talent-on-demand"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Build My Team
        </a>
      </nav>
      <main className="space-y-2 md:space-y-4 mb-4 mt-[80px] overflow-hidden lg:mt-0">
          <img src={PlaceholderImage} alt="Talent On Demand" className="h-full w-full object-contain" />
      </main>
      {showOverlay && (
        <div>
          {
            <VideoEmbed
              videoPath={IntroVideo}
              onClose={() => setShowOverlay(false)}
            />
          }
        </div>
      )}
      <FooterV2 />
    </div>
  );
};

export default TalentOnDemandPage;
