import lt1 from "../../../Assets/homev2/Navbar/lt-1.png";
import lt2 from "../../../Assets/homev2/Navbar/lt-2.png";
import lt3 from "../../../Assets/homev2/Navbar/lt-3.png";

const LetsTalkNavMenu = () => {
  return (
    <div className="w-full max-w-[1500px] mx-auto flex justify-between py-8 px-4">
      <div className="flex-1 grid grid-cols-3 gap-10 pr-10">
        <a href='https://calendly.com/salesbox-meet/salesmrkt?month=2023-08'
          target='_blank'
          rel='noopener noreferrer'
          className="group h-full flex flex-col justify-between items-center cursor-pointer overflow-hidden">
          <img
            src={lt1}
            alt=""
            width="100%"
            height="100%"
            className="scale-100 group-hover:scale-110 transition-all ease-in-out duration-500"
          />
          <h3 className="mx-auto text-center mt-4 text-base font-normal text-black group-hover:text-[#84CC16] transition-all duration-300 ease-in-out">
            Live Meeting
          </h3>
        </a>
        <a href='https://calendly.com/salesbox-meet/salesmrkt?month=2023-08'
          target='_blank'
          rel='noopener noreferrer'
          className="group h-full flex flex-col justify-between items-center cursor-pointer overflow-hidden">
          <img
            src={lt2}
            alt=""
            width="100%"
            height="100%"
            className="scale-100 group-hover:scale-110 transition-all ease-in-out duration-500"
          />
          <h3 className="mx-auto text-center mt-4 text-base font-normal text-black group-hover:text-[#84CC16] transition-all duration-300 ease-in-out">
            Schedule Meeting
          </h3>
        </a>
        <a
          href='https://salesboxmarket.myshopify.com/'
          target='_blank'
          rel='noopener noreferrer' className="group h-full flex flex-col justify-between items-center cursor-pointer overflow-hidden">
          <img
            src={lt3}
            alt=""
            width="100%"
            height="100%"
            className="scale-100 group-hover:scale-110 transition-all ease-in-out duration-500"
          />
          <h3 className="mx-auto text-center mt-4 text-base font-normal text-black group-hover:text-[#84CC16] transition-all duration-300 ease-in-out">
            Send More Info
          </h3>
        </a>
      </div>
      {/* <div className="flex flex-col gap-8 min-w-[12rem]">
        <a
          href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Live Meeting
        </a>
        <a
          href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Schedule Meeting
        </a>
        <a
          href="https://salesboxmarket.myshopify.com/"
          className="text-black hover:text-[#84CC16] transition-all duration-300 ease-in-out"
        >
          Send More Info
        </a>
      </div> */}
    </div>
  );
};

export default LetsTalkNavMenu;
